import React from "react";
import Layout from "./../components/layout";
import Helmet from "react-helmet";
import favicon from "../../src/images/favicon.ico";

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null;
    }

    return StoryblokEntry.prepareStory(props);
  }

  static prepareStory(props) {
    const story = Object.assign({}, props.pageContext.story);
    const globalNavi = Object.assign({}, props.pageContext.globalNavi);
    story.content = JSON.parse(story.content);
    globalNavi.content = JSON.parse(globalNavi.content);

    return { story, globalNavi };
  }

  constructor(props) {
    super(props);

    this.state = StoryblokEntry.prepareStory(props);
  }

  render() {
    let content = this.state.story.content;
    let globalNavi = this.state.globalNavi.content;
    let dateCreated = this.state.story.created_at;

    return (
      <div>
        <Helmet
          title="Asia Jackowska portfolio"
          link={[
            {
              rel: "icon",
              type: "image/x-icon",
              href: favicon
            }
          ]}
        />
        <Layout
          globalNavi={globalNavi}
          content={content}
          dateCreated={dateCreated}
        />
      </div>
    );
  }
}

export default StoryblokEntry;
